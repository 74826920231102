<template>
  <div class="container">
    <div
      class="content"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div
        class="item"
        :class="{ item_1: index < 1, item_2: index > 0 }"
        v-for="(item, index) in info"
        :key="item.title"
        @click="jumpTo(item)"
      >
        <div class="title">{{ item.title }}</div>
        <div class="detail">
          <div
            class="itemDetail"
            v-for="(subitem, index2) in item.children"
            :key="subitem.title"
          >
            <div class="label" :class="['icon_' + (index2 + 1)]">
              {{ subitem.title }}
            </div>
             <div class="desc">{{subitem.value}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { postData, getData } from "@/api/index";
import {jump} from "@/utils/index";
import {getCookie} from "@/utils/cookie.js";

const businessType = '展厅';

export default {
  data() {
    return {
      info: {},
      loading: false,
      query:{}
    };
  },
  created() {
     
 this.getDataBySearch();
  },
  methods: {
    async getDataBySearch() {
      this.loading = true;
      this.query = {...this.$store.state.query,...this.$route.query };
     
      const {
        data: { jiedai, keqing, zhandou },
      } = await postData("/api/board_all/index",this.query);
      const cropId = getCookie("cropId");
      const {
        data: { type },
      } = await getData("/api/board/getBoardType",{crop_id: Number(cropId)});
      this.info = [
        {
          title: "接待核心指标",
          table: businessType == type ? "dashboard" : "board",
          children: [
            {
              title: "来店量",
              value: jiedai.count,
            },
            {
              title: "首次进店量",
              value: jiedai.first,
            },
            {
              title: "再次进店量",
              value: jiedai.again,
            },
            {
              title: "接待得分",
              value:jiedai.total,
            },
          ],
        },
        {
          title: "客情核心指标",
          table: "deal",
          children: [
            {
              title: "成交指数",
              value: keqing.index,
            },
            {
              title: "新增线索量",
              value: keqing.count,
            },
            {
              title: "累计有效线索量",
              value: keqing.total,
            },
            {
              title: "成交量",
              value: keqing.deal,
            },
            {
              title: "战败量",
              value: keqing.fail,
            },
          ],
        },
        {
          title: "战力核心指标",
          table: "battle",
          children: [
            {
              title: "战斗力指数",
              value: zhandou.core1,
            },
            {
              title: "成交率",
              value: zhandou.core2,
            },
            {
              title: "战败率",
              value:zhandou.core3,
            },
            {
              title: "爆灯率",
              value: zhandou.core4,
            },
            {
              title: "逾期率",
              value: zhandou.core5,
            },
          ],
        },
      ];
      this.loading = false;
    },
    jumpTo({table}){
      let path=this.$route.path
      if(path==='/company'){
        path='/store'
      }
      console.log(path);
       jump("/"+table+path,this,this.query);
      
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: url("../../assets/bg.png");
  background-size: contain;
  .content {
    .item {
      display: flex;
      flex-direction: column;
      margin-top: 0.185185rem /* 10/54 */;
      &.item_1 {
        width: 9.666667rem /* 522/54 */;
        height: 5.240741rem /* 283/54 */;
        background: url("../../assets/board.png");

        background-size: cover;
        .itemDetail{
          width: 50%;
          
         
        }
      }
      &.item_2 {
        width: 9.666667rem /* 522/54 */;
        height: 5.240741rem /* 283/54 */;
        background: url("../../assets/board.png");

        background-size: cover;
        .detail::after{
              content: '';
              display: block;
              width: 33.33%; 
        }
        .itemDetail{
          width: 33.33%;
         
        }

      }
      .title {
        margin-left: 0.481481rem /* 26/54 */;
        color: #00d5e9;
        font-size: 0.407407rem /* 22/54 */;
        height: 0.962963rem /* 52/54 */;

        line-height: 0.962963rem /* 52/54 */;
      }
      .detail {
        flex: 1;
        display: flex;
     
        justify-content: space-around;
        flex-wrap: wrap;
  
        padding: 0px 0.685185rem /* 37/54 */;
        align-items: center;
        .itemDetail {
          height: 1.388889rem /* 75/54 */;
        display: flex;
        flex-direction: column;
        align-items: center;
          .label {
            color: #fff;
            font-size: .296296rem /* 16/54 */;
            padding-left: 0.833333rem /* 45/54 */;
            white-space: nowrap;
            &.icon_1 {
              background: url("../../assets/icon_1.png") no-repeat;
              background-size: 0.703704rem /* 38/54 */ 0.351852rem /* 19/54 */;
              background-position: center left;
            }
            &.icon_2 {
              background: url("../../assets/icon_2.png") no-repeat;
              background-size: 0.703704rem /* 38/54 */ 0.351852rem /* 19/54 */;
              background-position: center left;
            }
            &.icon_3 {
              background: url("../../assets/icon_3.png") no-repeat;
              background-size: 0.703704rem /* 38/54 */ 0.351852rem /* 19/54 */;
              background-position: center left;
            }
            &.icon_4 {
              background: url("../../assets/icon_4.png") no-repeat;
              background-size: 0.703704rem /* 38/54 */ 0.351852rem /* 19/54 */;
              background-position: center left;
            }
            &.icon_5 {
              background: url("../../assets/icon_5.png") no-repeat;
              background-size: 0.703704rem /* 38/54 */ 0.351852rem /* 19/54 */;
              background-position: center left;
            }
          }
          .desc {
            margin-top: 0.27037rem /* 20/54 */;
            color: #00d5e9;
              width: 2rem /* 108/54 */;
              font-size: 0.462963rem /* 25/54 */;
              text-align: center;
              //    border:1px solid red;
              background: url("../../assets/line.png") no-repeat;
              background-size: contain;
              background-position: bottom;
              padding-bottom: 0.12963rem /* 7/54 */;
            
          }
        }
      }
    }
  }
}
</style>
